// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyBUBkoHdiSP7wGfYPPDGS9XgiowEYhuGdQ',
    authDomain: 'portal-dni.firebaseapp.com',
    projectId: 'portal-dni',
    storageBucket: 'portal-dni.appspot.com',
    messagingSenderId: '150234980738',
    appId: '1:150234980738:web:a6e8f959d2b5b8dcefef9b',
    measurementId: 'G-J879TEF6Y5'
  },
  production: true
};
